import React, { useContext } from 'react';
import styled from 'styled-components';
import { ExperimentIcon } from '../../images/buttons';
import Theme from '../helpers/Theme';
import { ModalContext } from '../helpers/AppContext';

export default function TryMeButton ({children}){
  const {setOpenModal } = useContext(ModalContext);
  const handleOpen = () => setOpenModal(true);

  return (
    <ButtonContentStyled onClick={handleOpen}>
      <ExperimentIcon/>
      <CTAStyled>{children}</CTAStyled>
    </ButtonContentStyled>
  )
};

const ButtonContentStyled = styled.div`
  margin: auto;
  display: flex;
  align-items: center;
  width: fit-content;
  background-color: ${Theme.red};
  padding: 16px 32px;
  border-radius: 32px;
  cursor: pointer;
`;

const CTAStyled = styled.div`
  padding-left: 16px;
  font-weight: 700;
  color: white;
  text-transform: uppercase;
`;