import React, {useState} from "react";
import { NavigationStyled, NavLinksContainerStyled } from './styles';
import { Logo } from "../../../images";
import NavbarLinks from "./NavbarLinks";
import {ReactComponent as OpenIcon} from '../../../images/navbar/menu-base.svg'
import {ReactComponent as OpenClose} from '../../../images/navbar/menu-close.svg'

export default function PublicNavBar() {
  const [show, setShow] = useState(false);
  const handleMenu = () => setShow(!show);

  return (
    <NavigationStyled show={show}>
      <div className="logo">
        <Logo />
        <h2>BAUSTACK</h2>
      </div>
      <div className="menu" onClick={handleMenu}>
        <div className="trigger" >
          {show ? <OpenClose /> :  <OpenIcon />}
        </div>
        <NavLinksContainerStyled show={show}>
          <NavbarLinks setShow={setShow}/>
        </NavLinksContainerStyled>
      </div>
    </NavigationStyled>
  );
}
