import React from 'react';
import styled from 'styled-components';
import {Row, Wrapper, Text} from "../helpers";
import shortID from 'shortid';
import Zoom from 'react-reveal/Zoom';

import Lottie from 'react-lottie';
import * as FirstAnim from './Animations/phone-dashboard.json';
import * as SecondAnim from './Animations/phone-messages.json';
import * as ThirdAnim from './Animations/desk.json';
import * as FourthAnim from './Animations/social.json';
import * as FifthAnim from './Animations/sharing.json';
import * as SixthAnim from './Animations/analytics.json';

const dataFeatures = [
  {
    text: 'Crea tu propia base de datos y colabora en tiempo real con tu equipo y clientes',
    animation: FirstAnim,
  },
  {
    text: 'Crea tu panel de indicadores y mejora el control de tu negocio',
    animation: SecondAnim,
  },
  {
    text: 'Crea un sistema de monitoreo de servicios y comparte estatus en tiempo real con tus clientes',
    animation: ThirdAnim,
  },
  {
    text: 'Implementa repositorios compartidos con tus clientes de manera segura',
    animation: FourthAnim,
  },
  {
    text: 'Olvídate de los correos, lleva tus registros a la nube y accede desde cualquier dispositivo',
    animation: FifthAnim,
  },
  {
    text: 'Crea un sistema de alertas automáticas para ahorrar tiempo y cumplir todos tus plazos',
    animation: SixthAnim,
  },
];


const DataBlockStyled = styled.div`
  min-height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  p {
    font-size: 24px;
    line-height: 2em;
    max-width: 600px;
    padding: 32px 32px 0 32px;
    text-align: center;
  }
  
  @media screen and (min-width: 768px){
    min-height: auto;
  }
`;

const DataContentStyled = styled.div`
  max-width: 600px;
  padding: 16px;
  text-align: center;

  p {
    font-size: 16px;
    line-height: 2em;
  }
  
  @media screen and (min-width: 768px) {
    flex-direction: ${({ odd }) => odd ? 'row-reverse' : 'row'};
    max-width: 1000px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    p {
      font-size: 24px;
    }
  }
`;


export default function SectionFeatures() {
  return (
    <Wrapper style={{margin: "5em 0 10em 0",}}>
      <Row>
        <Text.TitleContainer>
          <Text.H2>Todo lo que puedes hacer con Baustack®</Text.H2>
        </Text.TitleContainer>
        {dataFeatures.map((el, idx) => (
          <DataBlockStyled key={shortID.generate()}>
            <Zoom>
              <DataContentStyled odd={idx % 2 === 0}>
                <p>{el.text}</p>
                <Lottie options={{
                  loop: true,
                  autoplay: true,
                  animationData: el.animation,
                }}/>
                <aside>
                  {el.img}
                </aside>
              </DataContentStyled>
            </Zoom>
          </DataBlockStyled>
        ))}
      </Row>
    </Wrapper>
  );
};