import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Pagination } from "swiper";
import "swiper/swiper-bundle.css";
import styled from "styled-components";
import { Text } from "../helpers";
import TryMeButton from "../actionButton/TryMeButton";

SwiperCore.use([Navigation, Pagination]);

const Slider = ({ data }) => (
  <SwiperContainer>
    <Swiper
      tag="section"
      wrapperTag="ul"
      navigation
      centeredSlides={true}
      spaceBetween={1}
      slidesPerView={1}
      breakpoints={{
        320: {
          width: 320,
          slidesPerView: 1,
        },
        375: {
          width: 375,
          slidesPerView: 1,
        },
        480: {
          width: 480,
          slidesPerView: 1,
        },
        768: {
          width: 600,
          slidesPerView: 1,
        },
        964: {
          width: 800,
          slidesPerView: 1,
        },
      }}
    >
      {data.map((el, idx) => {
        return (
          <SwiperSlide key={`slide-${idx}`} tag="li" >
            <Content>
              <Figure>{el.img}</Figure>
              <FigCaption>
                <Text.H3>{el.title}</Text.H3>
                <Text.P>{el.description}</Text.P>
                <TryMeButton to="#">PRUEBA GRATUITA</TryMeButton>
              </FigCaption>
            </Content>
          </SwiperSlide>
        );
      })}
    </Swiper>
  </SwiperContainer>
  );

const SwiperContainer = styled.div`
  margin: auto;
  width: 100%;
  max-width: 280px;
  @media screen and (min-width: 320px) {
    max-width: 320px;
  }
  @media screen and (min-width: 375px) {
    max-width: 375px;
  }
  @media screen and (min-width: 480px) {
    max-width: 480px;
  }
  @media screen and (min-width: 768px) {
    max-width: 600px;
  }
  @media screen and (min-width: 964px) {
    max-width: 800px;
  }
`;


const Content = styled.div`
  display: grid;
  height: 100%;
  grid-template-rows: auto 1fr;
  row-gap: 20px;
  padding: 1em 0;
`;

const Figure = styled.figure`
  display: flex;
  img {
    width: 280px;
    @media screen and (min-width: 768px) {
      width: 360px;
    }
  }
`;

const FigCaption = styled.figcaption`
  text-align: center;
  width: 100%;
  max-width: 500px;
  justify-self: center;
  p {
    padding: 32px 48px;
    line-height: 1.6em;
    font-style: italic;
  }
`;

export default Slider;
