import React from "react";
import styled from "styled-components";
import { Row, Wrapper } from "../helpers";
import Waves from "../helpers/Waves";
import Text from "../helpers/Text";
import TryMeButton from "../actionButton/TryMeButton";
import responsive from "../helpers/responsive";
import * as Social from '../../images/social';
import LogoCorfo from '../../images/footer/logo-corfo.png';
import LogoStartup from '../../images/footer/logo-startup.png';

export default function Footer() {
  return (
    <Wrapper gradient>
      <Waves.Top />
      <Row
        css={{
          default: `padding:16px;`,
          md: `padding: 8em 6em;`,
        }}>

        <Row
          css={{
            default: `display:grid;grid-template-rows: 230px 150px;`,
            md: `
          grid-template-rows: none;
          grid-template-columns: 1fr auto;
          `,
          }}
        >
          <TextContainer>
            <Text.H2>
              Baustack<sup>®</sup>
            </Text.H2>
            <Text.P>
              Desarrollamos herramientas <br />
              No-Code para empresas. <br />
              Encuentranos en redes sociales
            </Text.P>
            <List>
              <li>
                <a
                  href="https://www.linkedin.com/company/baustack/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Social.LinkedinIcon/>
                </a>
              </li>
              <li>
                <a
                  href=" https://www.instagram.com/baustackapp/"
                  target="_blank"
                  rel="noreferrer"
                >
                <Social.InstagramIcon/>
                </a>
              </li>
              <li>
                <a
                  href="https://www.facebook.com/Baustack/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Social.FacebookIcon/>
                </a>
              </li>
            </List>
          </TextContainer>
          <ButtonContainer>
            <TryMeButton>PRUEBA GRATUITA</TryMeButton>
          </ButtonContainer>
        </Row>
        <Sponsors>
          <h4>Baustack® cuenta con el apoyo de</h4>
          <div>
            <figure>
              <img src={LogoCorfo} alt="Logo Corfo"/>
            </figure>
            <figure>
              <img src={LogoStartup} alt="Logo StartUp Chile"/>
            </figure>
          </div>
        </Sponsors>
      </Row>
    </Wrapper>
  );
}

const TextContainer = styled.div`
  h2 {
    margin: 1em 0 0.5em;
    font-size: 38px;
    color: white;
  }
  p {
    line-height: 1.3em;
    color: white;
  }
`;
const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  ${responsive({
    md: `align-items: flex-end;margin-bottom: 3em;justify-content: center;`,
  })}
`;

const List = styled.ul`
  display: flex;
  margin-top: 1.5em;

  li:not(:last-child) {
    margin-right: 1em;
  }
  svg {
    width: 36px;
  }
`;

const Sponsors = styled.div`
  h4{
    border-top: 1px solid rgb(230,230,230);
    text-align: center;
    font-size: 20px;
    line-height: 1.5em;
    color: rgb(230,230,230);
    padding-top: 32px;
    @media screen and (min-width: 768px) {
      font-size: 24px;
      padding-top: 64px;
      margin-top: 64px;
    }
  }
  div{
    padding-top: 32px;
    img{
      max-width: 200px;
      margin-bottom: 16px;
    }
  }
  
  @media screen and (min-width: 768px) {
    div {
      display: flex;
      align-items: center;
      justify-content: center;
      figure {
        img {
          max-width: 300px;
          margin-right: 32px;
          margin-bottom: 0;
        }
      }
    }
  }
`;