import styled, { keyframes } from "styled-components";
import responsive from "../../helpers/responsive";

const TitleContainerStyled = styled.div`
  max-width: 700px;
  text-align: center;
  margin: auto;
  padding: 196px 0 128px 0;
  h1 {
    padding-bottom: 32px;
    ${responsive({
      xl: `
        font-size: 42px;
        line-height: 1.6em;
    `})}
  }
`;

const playAnimation = keyframes`
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.25);
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
`;

const MainViewGroupStyled = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  button {
    @media screen and (max-width: 768px) {
      transform: scale(0.5);
    }
    border-radius: 100%;
    border: 0;
    margin: auto;
    position: absolute;
    z-index: 10000;
    background-color: #5A3DEC;
    height: 200px;
    width: 200px;
    display: flex;
    justify-content: center;
    justify-items: center;
    align-items: center;
    & > .play-container {
      padding-left: 18px;
      svg {
        height: 64px;
      }
    }
    .pulse {
      background-color: #5A3DEC;
      animation: ${playAnimation} 2s ease infinite;
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      border-radius: 100%;
    }
    .pulse:nth-of-type(2) {
      animation-delay: 250ms; 
    }
    .pulse:nth-of-type(3) {
      animation-delay: 500ms;
    }
  }
  img{
    width: 60vw;
    border-radius: 8px;
    box-shadow: 4px 4px 10px rgb(50,50,50);
    transition: 400ms ease transform;
    cursor: pointer;
    transform-style: preserve-3d;
    
    ${
      responsive({
        md: 'width: 50vw;',
      })
    }
    
    &:nth-of-type(1),
    &:nth-of-type(3){
      transform: scale(.8);
      position: absolute;
      top: 0;
      z-index: 10;
    }
    &:nth-of-type(1){
      left: 0px;
      &:hover{
        transform: perspective(80vw) rotateX(-15deg) rotateY(30deg) translateX(-100px);
      }
    }
    &:nth-of-type(2){
      margin: auto;
      z-index: 11;
      transform: translateZ(1000px);
      &:hover{
        transform: scale(1.2);
      }
    }
    &:nth-of-type(3){
      right: 0;
      &:hover{
        transform: perspective(80vw) rotateX(15deg) rotateY(-30deg) translateX(100px);
      }
    }
  }
`;

export {
  TitleContainerStyled,
  MainViewGroupStyled,
}