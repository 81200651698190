import React from 'react';
import styled from 'styled-components';
import responsive from './responsive';
import Theme from "./Theme";

const H1Styled = styled.h1`
  font-size: 32px;
  line-height: 42px;
  color: ${props => props.color || Theme.black};
  ${props => props.style ||  ''}
`;

const H2Styled = styled.h2`
  font-size: 26px;
  line-height: 40px;
  color: ${props => props.color || Theme.black};
  ${props => props.style ||  ''}
  ${responsive({md: `font-size: 29px;`})}
`;

const H3Styled = styled.h3`
  font-size: 29px;
  color: ${props => props.color || Theme.black};
  ${props => props.style ||  ''}
  ${responsive({md: `font-size: 32px;`})}
`;

const PStyled = styled.p`
  color: ${props => props.color || Theme.black};
  ${props => props.style ||  ''}
`;

export const TitleContainerStyled = styled.div`
  text-align: center;
  padding: 0 16px;
  ${responsive({ md: `margin: 3em auto 5em;max-width: 550px;` })}
`;

const H1 = props => <H1Styled {...props} />
const H2 = props => <H2Styled {...props} />
const H3 = props => <H3Styled {...props} />
const P = props => <PStyled {...props} />
const TitleContainer = props => <TitleContainerStyled {...props} />

const Set = { H1, H2, H3, P, TitleContainer }

export default Set;