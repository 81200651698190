import * as React from "react";
import Layout from "./../components/helpers/Layout";
import PublicHeader from './../components/header/PublicHeader';
import SectionFeatures from "../components/sectionFeatures/SectionFeatures";
import SectionWhatWeDo from "./../components/sectionWhatWeDo/SectionWhatWeDo";
import Footer from "./../components/footer/Footer";
import Testimonials from "./../components/sectionTestimonials/Testimonials";

const IndexPage = () => {
  return (
    <Layout
      title={'Baustack - Desarrollamos herramientas No-Code para EMPRESAS.'}
      metaDescription={'Potencia la experiencia de tus clientes creando aplicaciones simples y en la nube con nuestra tecnología "No Code". ¡Pruébalo gratis!'}
    >
      <PublicHeader/>
      <SectionFeatures/>
      <SectionWhatWeDo/>
      <Testimonials/>
      <Footer/>
    </Layout>
  );
};

export default IndexPage;
