import React, {useEffect, useState} from 'react';
import styled, { keyframes } from "styled-components";

const FirstBlock = keyframes`
  0% {
   transform: rotateX(90deg) translateY(-100%);
  }
  100% {
    transform: rotateX(0) translateY(0);
  }
`;

const LastBlock = keyframes`
  0% {
    transform: rotateX(0) translateY(0);
  }
  100% {
    transform: rotateX(-90deg) translateY(100%);
    opacity: 0;
  }
`;


const CounterStyled = styled.div`
  display: flex;
  flex-direction: column;
  & > div {
    display: flex;
    width: 100%;
    justify-content: center;
    animation-duration: 500ms;
    animation-fill-mode: forwards;
    animation-timing-function: ease;
    
    & > div {
      background-color: white;
      color: #3A0CA3;
      padding: 8px;
    }
  }
  & > div:nth-of-type(1) {
    position: absolute;
    animation-name: ${FirstBlock};
  }
  & > div:nth-of-type(2) {
    animation-name: ${LastBlock};
  }
`;

export default function PublicHeader() {
  const [displayPosition, setDisplayPosition] = useState(0);
  const options = [
    'tus propias aplicaciones',
    'tu propio portal de clientes',
    'tu primer CRM',
    'tu gestor documental',
    'tu gestor de proyectos',
    'tu sistema de notificaciones',
    'tus encuestas de servicio',
  ];
  const optionsLen = options.length;
  useEffect(() => {
    setTimeout(() => {
      setDisplayPosition(optionsLen - 1 > displayPosition ? displayPosition + 1 : 0)
    }, 1500);
  }, [displayPosition, optionsLen]);

  const Counter = ({ displayPosition, options, optionsLen }) => {
    const after = displayPosition === 0 ? options[optionsLen -1] : options[displayPosition - 1];
    return <CounterStyled>
      <div>
        <div>{options[displayPosition]}</div>
      </div>
      <div>
        <div>{after}</div>
      </div>
    </CounterStyled>;
  }

  return (
    <Counter
      displayPosition={displayPosition}
      options={options}
      optionsLen={optionsLen}
    />
  );
};