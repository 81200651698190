import React from 'react';
import styled from "styled-components";
import responsive from "./responsive";

const RowStyled = styled.section`
  margin: auto;
  max-width: 1400px;
  ${
    ({css}) => responsive(css)
  }
`;

const Row = props => <RowStyled {...props} />

export default Row;