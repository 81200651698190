import React from "react";
import styled from 'styled-components';
import { Row, Text, Wrapper } from "../helpers";
import ContainerStyled from "../helpers/styles/ContainerStyled";
import Slider from "./Slider";
import RecreativaImage from '../../images/clientes/recreativa.jpeg';
import PYAImage from '../../images/clientes/pya.png';
import SintonizaImage from '../../images/clientes/sintoniza.png';

const data = [
  {
    img: <img src={RecreativaImage} alt="imgSlider" />,
    title: "Recreactiva",
    description: "“Implementamos una plataforma de seguimiento de campañas de vacunación en cientos de sucursales utilizando Baustack, con distintos niveles de acceso para cada miembro de nuestro equipo y nuestros clientes.”",
  },
  {
    img: <img src={SintonizaImage} alt="imgSlider" />,
    title: "Sintoniza",
    description:
      "“Con Baustack logramos crear una bitácora de capacitación con cientos de usuarios en minutos, consolidando así toda la información de los participantes en una única base de datos y realizar mejores análisis en tiempo real.”",
  },
  {
    img: <img src={PYAImage} alt="imgSlider" />,
    title: "Prado & Aylwin",
    description: "“Baustack revolucionó la forma de trabajar de nuestro estudio. Ahora tenemos un seguimiento continuo de las causas judiciales de nuestros clientes y de las tareas asignadas a cada miembro del equipo.”",
  }
];

function Testimonials() {

  return (
    <Wrapper style={{ margin: '5em 0', }}>
      <ContainerStyled>
        <Row
          css={{
            default: `display: grid;grid-template-rows: max-content 1fr;`,
          }}
        >
          <Text.TitleContainer>
            <Text.H2>
              Cada vez más empresas confían en Baustack
            </Text.H2>
          </Text.TitleContainer>
          <SlideContainer>
            <Slider data={data} />
          </SlideContainer>
        </Row>
      </ContainerStyled>
    </Wrapper>
  );
}

const SlideContainer = styled.div`
  margin: 0 -16px;
  width: auto;
`;

export default Testimonials;
