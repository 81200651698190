import React from "react";
import styled from "styled-components";
import Waves from "../helpers/Waves";
import { Row, Wrapper, Text } from "../helpers";
import Spinning from "../../images/spinning.png";
import ImportantShape from "../../images/important-shape.png";
import TryMeButton from "../actionButton/TryMeButton";
import * as Icons from '../../images/sectionIcons';
import responsive from "../helpers/responsive";
import shortID from "shortid";

const dataGroup = [
  {
    icon: <Icons.IdeaIcon />,
    text: 'Define los campos de tu base de datos sin ninguna línea de código',
  },
  {
    icon: <Icons.CodeIcon />,
    text: 'Establece relaciones entre tablas',
  },
  {
    icon: <Icons.GaugeIcon />,
    text: 'Construye tu propio panel de indicadores',
  },
  {
    icon: <Icons.DatabaseIcon />,
    text: 'Crea automatizaciones y validaciones para tus datos',
  },
  {
    icon: <Icons.EditIcon />,
    text: 'Filtra, ordena y asigna responsables',
  },
  {
    icon: <Icons.ClockIcon />,
    text: 'Programa notificaciones por correo',
  },
  {
    icon: <Icons.ShareIcon />,
    text: 'Comparte tu información con colaboradores y clientes',
  },
  {
    icon: <Icons.MultiplatformIcon />,
    text: 'Accede desde cualquier dispositivo de forma instantánea',
  },
];


export default function SectionWhatWeDo() {
  return (
    <Wrapper gradient={true} style={{ marginTop: "5em" }}>
      <Waves.Top />
        <Row
          css={{
            default: `
              display: grid;
              grid-template-rows: auto 1fr;
              padding-top: 5em;
            `,
              md: `
                grid-template-columns: 1fr 1fr;
                grid-template-rows: auto;
            `,
          }}
        >
          <ActionContainerStyled>
            <div>
              <FigureSpinning>
                <img src={Spinning} alt="" />
              </FigureSpinning>
              <ImportantShapeImage src={ImportantShape} alt="" />
              <Text.H3 color={"white"} className="animate">
                ¿Cómo <br />
                funciona <br />
                Baustack?
              </Text.H3>
              <p style={{
                color: 'white',
                padding: '0 16px 32px 16px',
                marginTop: '-20px',
                lineHeight: '1.5em',
                maxWidth: '350px',
                marginLeft: 'auto',
                marginRight: 'auto',
                zIndex: 10,
              }}>
                Co-creamos aplicaciones en minutos utilizando la tecnología <b style={{
                  fontWeight: 700,
              }}>“No Code”</b><br />
                Cuéntanos tu proceso de negocio y construyamos juntos tu primer sistema.
              </p>
              <div style={{ textAlign: 'left', }}>
                <TryMeButton to="#">PRUEBA GRATUITA</TryMeButton>
              </div>
            </div>
          </ActionContainerStyled>
          <div>
            {dataGroup.map(el => (
              <RectangleContainerBox key={shortID.generate()}>
                <RectangleBox>
                  {el.icon}
                  <p>{el.text}</p>
                </RectangleBox>
              </RectangleContainerBox>
            ))}
          </div>
        </Row>
      <Waves.Bottom />
    </Wrapper>
  );
}

const ImportantShapeImage = styled.img`
  bottom: -100px;
  position: absolute;
  max-width: 600px;
  ${responsive({
    md: `
      left: -300px;
      max-width: 800px;
    `,
    lg: `
      left: -150px;
    `,
    xl: `
      left: -50px;
    `,
  })}
`;

const ActionContainerStyled = styled.div`
  z-index: 1;
  ${responsive({ md: `margin-bottom: 8em; padding-top: 64px;` })}

  & > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @media screen and (min-width: 768px) {
      position: sticky;
      height: 300px;
      top: calc(50vh - 150px);
    }
    h3 {
      z-index: 3;
      text-align: center;
      line-height: 1.5em;
      margin-bottom: 1em;
      ${responsive({ md: `text-align: left;font-size: 38px;` })}
    }
  }
`;

const FigureSpinning = styled.figure`
  position: absolute;
  bottom: 150px;
  left: -100px;
  z-index: 2;
  ${responsive({ md: `left: -160px;` })}
  ${responsive({ lg: `left: -250px;bottom: 80px;` })}
  img {
    @keyframes rotating {
      from {
        -webkit-transform: rotate(0deg);
      }
      to {
        -webkit-transform: rotate(360deg);
      }
    }
    animation: rotating 30s linear infinite;
    max-width: 320px !important;
    ${responsive({ md: `max-width: 500px!important;` })}
    ${responsive({ lg: `max-width: 650px!important;` })}
  }
`;

const RectangleContainerBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 64px auto;
  padding: 0 16px;
  z-index: 10;
  @media screen and (min-width: 768px) {
    padding: 0;
    margin-bottom: 128px;
  }
`;

const RectangleBox = styled.div`
  background-color: #3a0ca3;
  color: white;
  display: grid;
  grid-template-rows: auto 1fr;
  padding: 24px;
  border-radius: 16px;
  box-shadow: 10px 10px 4px rgba(0, 0, 0, 0.25);
  font-weight: bold;
  font-size: 18px;
  align-items: center;
  width: 100%;
  max-width: 400px;
  p {
    line-height: 1.5em;
    text-align: center;
    font-weight: 400;
  }
  svg {
    transform: scale(0.5);
    margin: auto;
  }
  @media screen and (min-width: 768px) {
    grid-template-columns: auto 1fr;
    align-items: center;
    text-align: left;
    padding: 32px;
    border-radius: 32px;
    svg {
      transform: none;
    }
    p {
      margin: 0 15px 0 35px;
      text-align: left;
    }
  }
`;
