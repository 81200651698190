import styled from "styled-components";
import responsive from "../../helpers/responsive";
import Theme from "../../helpers/Theme";

const NavigationStyled = styled.nav`
  display: grid;
  grid-template-columns: 1fr auto;
  justify-content: space-between;
  top: 0;
  position: fixed;
  z-index: 100;
  left: 0;
  right: 0;
  padding: 16px;
  max-width: 1360px;
  
  ${responsive({
    md: `
      grid-template-columns: auto 1fr;
      background-color: ${ Theme.darkmauve };
      padding-right: 32px;
      border-radius: 40px;
      margin: 16px auto 0 auto;
      left: 16px;
      right: 16px;
    `,
  })}

  svg {
    height: 24px;
    width: 24px;
  }

  .logo {
    background-color: ${Theme.darkmauve};
    display: flex;
    align-items: center;
    padding-left: 16px;
    border-radius: 24px 0 0 24px;

    ${responsive({
      md: `
        background-color: white;
        justify-content:center;
        width:40px;
        height:40px;
        border-radius:50%;
        padding-left: 0px;
      `,
    })}
    
    h2 {
      color: ${Theme.mauve};
      text-align: center;
      width: 100%;
      ${responsive({ md: `display: none;` })}
    }
    
    svg {
      fill: ${Theme.mauve};
      ${
        responsive({
          md: `fill: ${Theme.darkmauve};
          width: 20px;
          height:20px;
          `
        })}
      path{
        fill: ${Theme.darkmauve};
      }
    }
  }

  .menu {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .trigger {
      background-color: ${Theme.mauve};
      height: 48px;
      width: 48px;
      transition: border-radius 200ms ease;
      border-radius: ${({ show }) => show ? '0 24px 0 0' : '0 24px 24px 0'};
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      transform: translateZ(2000px);

      ${responsive({ md: `display: none;` })}
      svg {
        stroke: ${Theme.darkmauve};
      }
    }
  }
`;

const NavLinksContainerStyled = styled.div`
  transition: height 200ms ease;
  position: absolute;
  top: 24px;
  right: 0;
  z-index: -1;
  height: ${({ show }) => show ? 236 : 0 }px;
  overflow: hidden;
  
  ul {
    display: flex;
    flex-direction: column;
    padding: 16px 32px;
    background-color: ${Theme.darkmauve};
    border-radius: 0 0 32px 32px;
    border-top: 28px solid ${Theme.mauve};
    
    li {
      margin: 16px 0;
      display: flex;
      justify-content: flex-end;
    }

    ${responsive({ md: `display: flex;` })}
  }

  @media screen and (min-width: 768px) {
    display: flex;
    position: relative;
    height: auto;
    z-index: 1;
    top: auto;
    
    ul{
      flex-direction: row;
      padding: 0;
      border-top: 0;
      li{
        margin: 0 16px 0 0 ;
        &:last-of-type {
          margin: 0;
        }
      }
    }
  }
`;

export {
  NavigationStyled,
  NavLinksContainerStyled,
}