import React, { useState } from 'react';
import { Wrapper, Row, Text } from '../../helpers';
import PublicNavBar from "../NavBar/PublicNavBar";
import Waves from '../../helpers/Waves';
import TryMeButton from '../../actionButton/TryMeButton';
import { TitleContainerStyled, MainViewGroupStyled } from './styles';
import StatsImage from '../../../images/header/estadisticas.png';
import DashboardImage from '../../../images/header/dashboard.png';
import TableImage from '../../../images/header/main-table.png';
import styled  from "styled-components";
import Options from './Options';
import {Modal} from 'rsuite';
import Theme from '../../helpers/Theme';

const CloseButton = styled.button`
  background-color: ${Theme.red};
  padding: 2px 12px;
  border-radius: 8px;
  font-size: 10px;
  font-weight: 600;
  color: white;
`;

const HeaderModal = styled.header`
  display: flex;
  justify-content: space-between;
  h1 {
    color: white;
  }
`;

const VideoContainer = styled.iframe`
  width: 80vw;
  height: 40vw;
  margin-top: 16px;
  border-radius: 16px;
`;

export default function PublicHeader() {
  const [showVideo, setShowVideo] = useState(false);

  return (
    <Wrapper gradient={true}>
      <Modal
        show={showVideo}
        backdropClassName="video-modal"
        style={{
          fontFamily: "'Poppins', serif, sans-serif",
          lineHeight: '1.5em',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <HeaderModal>
          <h1>Baustack®</h1>
          <CloseButton onClick={() => setShowVideo(false)}>Cerrar</CloseButton>
        </HeaderModal>
        <VideoContainer
          src="https://www.youtube.com/embed/k7Tnj4Cnezk?controls=0&autoplay=1"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      </Modal>
      <PublicNavBar/>
      <Row>
        <TitleContainerStyled>
          <Text.H1 color="white">
            Con Baustack® crea <Options /> sin conocimientos técnicos
          </Text.H1>
          <TryMeButton>Prueba gratuita</TryMeButton>
        </TitleContainerStyled>
      </Row>

      <Row>
        <MainViewGroupStyled>
          <button onClick={() => setShowVideo(true)}>
            <div className="play-container">
              <svg width="109" height="127" viewBox="0 0 109 127" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M109 63.5L0.249994 126.287L0.25 0.713153L109 63.5Z" fill="white"/>
              </svg>
            </div>
            <aside className="pulse" />
            <aside className="pulse" />
            <aside className="pulse" />
          </button>
          <img src={StatsImage} alt="Stats"/>
          <img src={TableImage} alt="Table"/>
          <img src={DashboardImage} alt="Dashboard"/>
        </MainViewGroupStyled>
      </Row>

      <Waves.Bottom/>
    </Wrapper>
  );
};