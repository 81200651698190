import * as React from "react"
import styled from 'styled-components';
import * as Icons from '../../../images/navbar';
import shortID from "shortid";
import {useContext} from "react";
import {ModalContext} from "../../helpers/AppContext";

const NavbarLinks = ({ setShow }) => {
  const {setOpenModal } = useContext(ModalContext);
  const handleModal = (e, isExternal) => {
    if (!isExternal) {
      e.preventDefault();
      setOpenModal(true);
    }
    setShow(false);
  }
  const arrayLinks = [
    {
      icon: <Icons.IdeaIcon />,
      text: 'SERVICIOS',
    },
    // {
    //   icon: <Icons.CreditCardIcon />,
    //   text: 'PRECIOS',
    //   href: '/',
    // },
    {
      icon: <Icons.ContactIcon />,
      text: 'CONTACTO',
    },
    {
      icon: <Icons.UserIcon />,
      text: 'LOGIN',
      href: 'https://app.baustack.com',
      external: true,
    },
  ];

  return (
    <List>
      {arrayLinks.map(el => {
        return (
          <ListItem key={shortID.generate()}>
            <Link
              onClick={e => handleModal(e, el.external)}
              target={el.external ? '_blank' : '_self'}
              href={el.href}
              rel="noreferrer"
            >
              {el.icon} {el.text}
            </Link>
          </ListItem>
        );
      })}
    </List>
  );
};

export const List = styled.ul`
  display: flex;
  list-style: none;
`;

export const ListItem = styled.li`

`;

export const Link = styled.a`
  font-weight: bold;
  color: white;
  align-items: center;
  display: flex;
  text-decoration: none;
  cursor: pointer;
  transform: translateZ(2000px);

  &:hover {
    color: rgb(200, 200, 200);
    text-decoration: none;
  }
  svg {
    margin: 0 10px 4px 0;
  }
`

export default NavbarLinks;